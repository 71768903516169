<template>
  <div class="news">
    <section class="mobile-news">
      <div class="container">
        <div class="swiper-container gallery-top">
          <div class="swiper-wrapper">
            <router-link
              tag="div"
              class="swiper-slide"
              v-for="(news, index) in newsMobile"
              :key="index"
              :to="{ name: 'news-detail', params: { slug: news.id } }"
            >
              <img :src="news.locale.image.preview" alt="" />
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="web-news">
      <div class="container">

        <h2>{{ localize('last-news') }}</h2>

        <div class="row">
          <div
            class="col-xl-4 col-ml-4 col-lg-6 col-sm-12 mb-32"
            v-for="(newsData, i) in items"
            :key="i"
          >
            <card-new
              :id="newsData.id"
              :slug="newsData.locale.slug"
              :image="newsData.locale.image"
              :heading="newsData.locale.preview_text"
              :title="newsData.locale.title"
              :date="newsData.date"
            />
          </div>
        </div>

        <paginate
          class="mt-50"
          :page-count="pageCount"
          :click-handler="pageChangeHandler"
          :prev-text="'Назад'"
          :next-text="'Следующая'"
          prev-class="prev"
          next-class="next"
          :container-class="'pagination'"
          :page-class="'sum'"
        />

      </div>
    </section>
  </div>
</template>

<script>
import cardNew from '@/components/NewsCard.vue';
import cardsNew from '@/data/cardNew.js';
import paginationMixin from '../mixins/pagination.mixin.js';
import localize from '../filters/localize.filter';
import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.min.css';

export default {
  components: {
    cardNew
  },
  mixins: [paginationMixin],
  data: () => ({
    cardsNew,
    news: [],
    newsMobile: [],
    language:
      localStorage.getItem('lang') === 'ru-RU' || !localStorage.getItem('lang')
        ? 'ru'
        : 'uz'
  }),
  methods: {
    localize,
    setup() {
      this.setUpPaginetion(this.news);
    },
    async fetchNews() {
      try {
        const { data: news } = await this.$axios.get('news/list', {
          headers: {
            'Content-Language': this.$i18n.locale
          }
        });

        if (news.status === 'success') {
          this.news = news.data.filter(({ is_mobile }) => is_mobile === 0);
          this.newsMobile = news.data.filter(
            ({ is_mobile }) => is_mobile === 1
          );
        } else {
          news.response.errors.forEach((error) => this.$toastError(error));
        }
      } catch (e) {
        this.$toastError(e.message);
      }
    }
  },
  async mounted() {
    await this.fetchNews();

    await this.setup();

    new Swiper('.gallery-top', {
      spaceBetween: 10,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        576: {
          slidesPerView: 1.1,
          spaceBetween: 15
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 2,
          spaceBetween: 32
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 32
        },
        1200: {
          slidesPerView: 2.3,
          spaceBetween: 32
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });

    this.pageSize = 10;

    const prev = document.querySelector('.pagination .prev a');
    const next = document.querySelector('.pagination .next a');
    prev.style = 'margin-right: 10px';
    next.style = 'margin-left: 10px';

    prev.innerHTML = `
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.21497 13.4299L0.784973 6.99995L7.21497 0.569946" stroke="#6610F5" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      ${localize('button')['back']}
    `;

    next.innerHTML = `
      ${localize('button')['next']}
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.504028 0.569946L6.93403 6.99995L0.504028 13.4299" stroke="#6610F5" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `;
  }
};
</script>

<style lang="scss" scoped>
ul.pagination {
  display: flex;
}

.mb-32 {
  margin-bottom: 32px;
}

.m-auto {
  margin: auto;
}

header {
  text-align: center;
  padding: 56px 0;
  background: $grey url('../assets/icons/Vector.svg') no-repeat
    calc(50% - 120px) 37px;

  h1 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
  }
}

section {
  &.mobile-news {
    margin: 100px 0 0 0;

    @media (max-width: $mobile) {
      margin: 60px 0 0 0;
    }

    .swiper-slide {
      cursor: pointer;

      img {
        border-radius: 8px;
      }
    }
  }
}

section.web-news {
  padding-bottom: 150px;
  padding-top: 80px;

  @media (max-width: $mobile) {
    padding-top: 0px;
  }

  h2 {
    text-align: center;
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
    margin-bottom: 40px;

    @media (max-width: $mobile) {
      font-size: 32px;
    }
  }
}

@media (max-width: 575px) {
  header {
    padding: 21px;

    h1 {
      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
    }
  }
  section {
    padding-top: 24px;
    padding-bottom: 86px;

    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      text-align: left;
      color: $black;
      margin-bottom: 8px;
    }
  }
}
</style>
